.app {
    text-align: center;
    font-family: 'Arial', sans-serif;
    background: #f4f5fa;
   
  
    .header {
      background-color: #4a3e85;
      color: white;
      padding: 50px;
      margin-top: auto;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
     
  
      img {
        border-radius: 50%;
        margin-right: 10px;
      }
  
      h1 {
        margin: 0;
      }
    }
  
    h2 {
      margin-bottom: 20px;
      color: #333;
    }
  
    .skills {
      display: flex;
      justify-content: space-around;
      margin-top: 20px;
  
      .soft-skills, .hard-skills {
        width: 45%;
  
        h3 {
          text-align: left;
          color: #4a3e85;
          margin-bottom: 10px;
          
          
        }
      }
    }
  }
  
  // Estilos para o gráfico circular
  .circular-progress {
    position: relative;
    width: 120px;
    height: 120px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  
    svg {
      position: absolute;
      top: 0;
      left: 0;
    }
  
    .circular-text {
      position: absolute; // Mudar para absolute para centralizar em relação ao círculo
      display: flex; // Usar flexbox para centralização
      justify-content: center; // Centralizar horizontalmente
      align-items: center; // Centralizar verticalmente
      width: 100%; // Garantir que ocupe toda a largura do círculo
      height: 100%; // Garantir que ocupe toda a altura do círculo
  
      h4 {
        margin: 0;
        color: #4a3e85;
        font-size: 24px;
        text-align: center;
        margin-left: 15px; // Centralizar texto
      }
    }
  }
  
  // Estilos para as barras de progresso e skills-card
  .skills-card {
    background: #ffffff;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
   
    .skills-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
  
      h4 {
        margin: 0;
        color: #4a3e85;
        background-color: #eef2ff;
        padding: 5px 10px;
        border-radius: 5px;
        
       
      }
  
      .percentage {
        background-color: #4a3e85;
        color: #eff309;
        padding: 5px 10px;
        border-radius: 5px;
      }
    }
  
    .progress-bar {
      background-color: #e0e0e0;
      border-radius: 5px;
      overflow: hidden;
      height: 10px;
      margin-bottom: 10px;
  
      .progress {
        background-color: #6a59c8;
        height: 100%;
      }
    }
  
    .description {
      font-size: 14px;
      color: #777;
    }
   .p{
      color: #4a3e85;
      font-size: 50px!important;
     
   }

   
  

   
    
  }
  