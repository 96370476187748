.containerWhite {
  align-self: center;
  width: 100%;   
  max-width: 1140px;
  display: flex;
  flex-direction: column;
  align-items: center; 
}

.edit-profile-container{

  width: 100%;
  box-shadow: 5px 5px 5px black;
  border-radius: 10px;
  flex-direction: "column",
 
 
}
.container-cadastro {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}
.label-saldo {
  background: none;
  width: auto;
}

.form {
  width: 100%;
}

.container-s {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.skill,
.label-skill {
  background-color: #fff;
  border-radius: 20px;
  padding: 2px;
  font-size: 1.4em;
  font-weight: bold;
  width: 40px;
  margin-left: 5px;
}
.container-skill {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}
.label-skill {
  background: none;
  width: auto;
}
.person{
  background-color: #ededed;  
}

.my-skills-container{ 
  width: 100%;
  display: flex;
  flex-direction: column;
  height: calc(90vh - 100px);
  
}

.my-skills{
  box-shadow: 5px 5px 5px #E5E7EB;
  border-radius: 10px 10px 0px 0px;

  th, td{
    padding: 20px;
  }

  th{
    color: #413C69;
    text-align: left!important;
  }

  td{
    color: #323233;
  }

  thead tr{
    background-color: #E5E7EB;
  }
  .custom-background {
    background-color: #e5e7eb !important;
  }
  
  .dropdown {
    margin: 20px auto; /* Centraliza o dropdown horizontalmente */
    width: 50%; /* Define uma largura adequada para a interface */
    
    .dropdown-toggle {
      background-color: #ffffff; /* Cor de fundo branco */
      border: 1px solid rgba(0, 0, 0, 0.1); /* Borda leve para combinar com os outros elementos */
      border-radius: 10px; /* Mesma borda arredondada dos outros elementos */
      width: 100%;
      padding: 10px 15px; /* Preenchimento interno para tornar o botão maior */
     // box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1); /* Sombra suave */
      text-align: left; /* Alinha o texto à esquerda */
      color: #413C69; /* Cor do texto consistente com o cabeçalho da tabela */
      font-weight: bold;
      font-size: 1.2em;
      &:hover, &:focus {
        background-color: #e5e7eb; /* Cor de fundo ao passar o mouse */
        border-color: #ccc; /* Muda a cor da borda ao focar */
      }
    }
  
    .dropdown-menu {
      width: 100%; /* Mesma largura do botão */
      border-radius: 10px; /* Bordas arredondadas */
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1); /* Sombra suave */
      padding: 0;
      border: none; /* Remove a borda padrão do Bootstrap */
    }
  
    .dropdown-item {
      padding: 10px 15px; /* Aumenta o espaçamento interno */
      background-color: #fff; /* Cor de fundo branca */
      color: #323233; /* Cor de texto consistente */
      border-bottom: 1px solid rgba(0, 0, 0, 0.1); /* Separadores entre os itens */
      &:hover {
        background-color: #e5e7eb; /* Cor de fundo ao passar o mouse */
      }
    }
  
    .dropdown-item:last-child {
      border-bottom: none; /* Remove a borda inferior do último item */
    }
  }

  // index.scss

.fade-in {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.fade-in.show {
  opacity: 1;
}

  
}