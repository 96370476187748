.container-search {
  padding: 15px;

  h1 {
    font-size: 1.1em;
  }

  .filter-skills,
  .filter-ranges {
    padding: 20px;
    margin-top: 10px;

    .filter {
      h2 {
        text-align: left;
        font-weight: bold;
      }
    }

    .search {
      max-width: 60%;
      display: flex;
      flex-direction: row;
      margin-bottom: 15px;
      border: 1px solid #E5E7EB;
      padding: 10px;

      .skills-search {
        min-width: 350px;
        margin-right: 10px;

        p {
          display: inline;
          padding: 10px;
          margin: 0px;
          font-size: 0.9em;
          color: #fff;
          text-transform: uppercase;
          border-radius: 20px;
          background-color: #059669;
        }
      }
    }
  }

  .title-skills-container {

    h2 {
      font-size: 0.7em;
      text-align: left;
      font-weight: bold;
    }
  }

  .skills-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .skills-card {
      margin-right: 10px;
      margin-bottom: 10px;

      .button-add {
        height: 40px;
        background-color: #ccc;
        border-radius: 20px;
      }
    }

    padding-bottom: 10px;
  }

  .button-search {
    max-width: 200px;
    margin-top: 10px;
  }

  .search-results {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    h2 {
      margin-top: 20px;
      font-size: 1.1em;
      text-align: left;
    }

    .row-result {
      display: flex;
      flex-direction: column;
      width: 25%;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 10px;
      margin-left: 20px;
      margin-top: 20px;

      h3 {
        margin: 0px;
        font-size: 1.1em;
        font-weight: bold;
        text-align: left;
      }

      .row-skills {
        display: flex;
        flex-direction: column;
        margin-top: 10px;

        .skill-user {
          display: flex;
          flex-direction: column;
          margin-bottom: 10px;
          padding-top: 20px;

          .skills-language {
            font-weight: 500;
          }

        }

        .points-language {
          display: flex;
          flex-direction: column;
          text-align: center;
          padding: 10px;
          border-radius: 10px;
          margin-right: 10px;

          &-green {
            @extend .points-language;
            background-color: #cf0;
          }

          &-yellow {
            @extend .points-language;
            background-color: #fc0;
          }

          &-red {
            @extend .points-language;
            background-color: #f2f2f2;
          }
        }

      }
    }
  }

  .progress-bar-container {
    background-color: #DFE4EA;
    border-radius: 15px;
    height: 15px;
    display: flex;
    position: relative;

    .progress-bar-foreground {
      flex: 1;
      background-color: #60A5FA;
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
    }

    .progress-bar-tooltip {
      background-color: #60A5FA;
      padding: 5px;
      border-radius: 10px;
      position: absolute;
      top: -30px;
      color: #fff;
      font-size: 0.8em;
    }
  }

  .icon {
    display: inline-block;
    cursor: pointer;
    transition: transform 0.3s ease, color 0.3s ease;
  }

  .icon:hover {
    transform: scale(1.2);
    color: red;
    animation: shake 0.3s;
  }

  /* Animação de tremida (shake) */
  @keyframes shake {
    0% {
      transform: translate(1px, 1px) rotate(0deg);
    }

    10% {
      transform: translate(-1px, -2px) rotate(-1deg);
    }

    20% {
      transform: translate(-3px, 0px) rotate(1deg);
    }

    30% {
      transform: translate(3px, 2px) rotate(0deg);
    }

    40% {
      transform: translate(1px, -1px) rotate(1deg);
    }

    50% {
      transform: translate(-1px, 2px) rotate(-1deg);
    }

    60% {
      transform: translate(-3px, 1px) rotate(0deg);
    }

    70% {
      transform: translate(3px, 1px) rotate(-1deg);
    }

    80% {
      transform: translate(-1px, -1px) rotate(1deg);
    }

    90% {
      transform: translate(1px, 2px) rotate(0deg);
    }

    100% {
      transform: translate(1px, -2px) rotate(-1deg);
    }
  }


//* Aumentar o padding e o tamanho dos botões */
.swal2-popup {
  font-size: 16px; /* Aumenta o tamanho da fonte */
  padding: 50px;
}

.swal2-confirm, .swal2-cancel {
  padding: 10px 20px!important; /* Ajusta o padding dos botões */
  font-size: 16px; /* Ajusta o tamanho da fonte dos botões */
  min-width: 120px; /* Define uma largura mínima para os botões */
}

/* Ajustar a largura dos botões, se necessário */
.swal2-actions {
  display: flex;
  justify-content: center; /* Ajusta o alinhamento dos botões */
}


  
}