.containerWhiteII {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  max-width: 1140px;
  
}

.my-interviews {
  box-shadow: 5px 5px 5px #E5E7EB;
  border-radius: 10px 10px 0px 0px;




  th,
  td {
    padding: 15px;
  }

  th {
    color: #413C69;
    text-align: left !important;

  }

  td {
    color: #323233;
  }

  thead tr {
    background-color: #E5E7EB;

  }
}

.my-interviews-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px; 
  
}

.my-interviwes {
  box-shadow: 5px 5px 5px #E5E7EB;
  border-radius: 10px 10px 0px 0px;

  th,
  td {
    padding: 20px;
  }

  th {
    color: #413C69;
    text-align: left !important;
  }

  td {
    color: #323233;
  }

  thead tr {
    background-color: #E5E7EB;
  }
}

.my-interviews-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  min-height: 100vh;
}

.footer {
  background-color: #413c69;
  color: white;
  padding: 10px 20px;
  text-align: center;
  width: 100%;
  margin-top: auto;
}

/* Aumentar o padding e o tamanho dos botões */
.swal2-popup {
  font-size: 16px; /* Aumenta o tamanho da fonte */
  padding: 50px;
}

.swal2-confirm, .swal2-cancel {
  padding: 10px 20px; /* Ajusta o padding dos botões */
  font-size: 16px; /* Ajusta o tamanho da fonte dos botões */
  min-width: 120px; /* Define uma largura mínima para os botões */
}

/* Ajustar a largura dos botões, se necessário */
.swal2-actions {
  display: flex;
  justify-content: center; /* Ajusta o alinhamento dos botões */
}
